import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty} from '@matchsource/utils';
import {notEmptyRegexpValidator} from '../utils';

export const TNC_CD34_KEY = 'tnc-cd34';

export const tncCd34Validator = (control: AbstractControl): ValidationErrors | null => {
  const {value} = control;

  if (isEmpty(value)) {
    return null;
  }

  const result = notEmptyRegexpValidator(/^\d{0,4}(\.\d)?$/, TNC_CD34_KEY)(control);

  if (result) {
    return result;
  }

  const val = +value;
  const isValid = val > 0.0 && val <= 9999;

  return !isValid ? {[TNC_CD34_KEY]: {value: control.value}} : null;
};
