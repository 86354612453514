import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty, isFutureDateTodayIncluded} from '@matchsource/utils';

export const FUTURE_DATE_TODAY_VALIDATOR_KEY = 'future-date-today';

export const futureDateTodayIncludedValidator = (control: AbstractControl): ValidationErrors | null => {
  const isValid = isEmpty(control.value) || isFutureDateTodayIncluded(control.value);

  return !isValid ? {[FUTURE_DATE_TODAY_VALIDATOR_KEY]: {value: control.value}} : null;
};
