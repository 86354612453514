import {BloodType} from '@matchsource/api-generated/subject';

import {Nomenclature} from '@matchsource/models/nomenclature';

export const fromDTO = (input: BloodType): Nomenclature => {
  return {
    code: input.code,
    description: input.description,
  };
};
